import './App.css';
import { HiMenu, HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineX, HiOutlineCheckCircle } from 'react-icons/hi';
import { Advantage, DetailsNotif, Category } from './data';
import { useResize } from './use-resize';
import { useState, useEffect } from 'react';
// import video from './png/bezlimit_video2.mp4';
import InputMask from "react-input-mask";
import { Link } from 'react-router-dom';
import axios from "axios";
import ReactLoading from 'react-loading';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { DeliveryForm } from './DeliveryForm';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import * as Scroll from 'react-scroll';
import { Link as ScrollLink } from 'react-scroll'

function Home() {
	const { isScreenLg, isScreenXl } = useResize(); // для адаптивного рендера
	const [hamburger, setHamburger] = useState(false); // телефонное меню
	const [checkNumber, setCheckNumber] = useState(''); // выбранный номер телефона

	const [name, setName] = useState(''); // переменные и функции для формы стать парнером в футере
	const [numberPhone, setNumberPhone] = useState('');
	const [checkbox, setCheckbox] = useState(false);
	const [successfulForm, setSuccessfulForm] = useState(false);

	const [error, setError] = useState(false);
	const [tarifs, setTarifs] = useState(null)

	function submitForm() {
		axios(`https://www.anncom.ru/dialer/lendingbezlimit/registration_partner.py`, {
			params: {
				cli_name: name,
				cli_phone: numberPhone,
			}
		})
		setSuccessfulForm(true)
	}

	const [dataValue, setDataValue] = useState('');
	const [imgList, setImgList] = useState([]);
	const [aboutColumns, setAboutColumns] = useState();
	const [advantageList, setAdvantageList] = useState()

	function closeSuccessfulForm() {
		setSuccessfulForm(false)
		setName('');
		setNumberPhone('');
		window.scrollTo(0, 0);
	}

	const [windowTime, setWindowTime] = useState(false); // окно по таймеру всплывающее справа внизу

	useEffect(() => {
		axios({
			method: 'get',
			url: 'https://api.bezlimit.club/all_data'
		}).then(res => {
			setImgList([])
			setDataValue(res.data.Response);
			setAboutColumns(JSON.parse(res.data.Response.aboutColumnsP));
			setAdvantageList(JSON.parse(res.data.Response.advantage))
			setTarifs(JSON.parse(res.data.Response.tarifs))
			const keysImgList = Object.keys(res.data.Response).filter(str => str.startsWith('image')).filter(str=> !str.startsWith('image_tarif_photo'))
			keysImgList.map(key => {
				setImgList(imgList => [
					...imgList,
					{
						name: key,
						image: res.data.Response[key]
					},
				]);
			})
		})
		setTimeout(function () {
			setWindowTime(true)
		}, 100000);
	}, [])

	function handlePhoneChange(e) {
		setNumberPhone(e.target.value);
	}

	function handleName(e) {
		let value = e.target.value.replace(/[0-9]/g, '');
		setName(value);
	}

	let numberSlider = 1; // параметры слайдера отзывов и картинок офиса
	let navigation = false;
	let spaceBetween = 20;
	isScreenLg ? numberSlider = 2.5 : numberSlider = 1.2;
	isScreenLg ? navigation = true : navigation = false;
	isScreenLg ? spaceBetween = 20 : spaceBetween = 4;

	const [errorTel, setErrorTel] = useState(false)
	function handleBlur() {
		if (numberPhone.indexOf('_') !== -1) {
			setErrorTel(true);
		} else {
			setErrorTel(false);
		}
	}

	return (
		<div>
			{windowTime ? <div className='window-time-fixed'>
				<div className='opacity-background'></div>
				<div className='window-time'>
					<HiOutlineX className='notification-icon' onClick={() => setWindowTime(false)} />
					<p className='header-content-font h8'>Нужна помощь в подборе номера?</p>
					<a href={dataValue.manager}>
						<button className='button-notification'>Написать менеджеру</button>
					</a>
				</div>
			</div> : <></>}
			{successfulForm ? (<div>
				<Link className='link' to='/'>
					<div className='hamburger-back' onClick={() => closeSuccessfulForm()}></div>
				</Link>
				<div className='notification-wrapper'>
					<Link className='link' to='/'>
						<HiOutlineX className='notification-icon' onClick={() => closeSuccessfulForm()} />
					</Link>
					<div className='notification-grid'>
						<HiOutlineCheckCircle className='order-icon' />
						<div className='margin-bottom'></div>
						<div className='margin-bottom'></div>
						<p className='notificarion-h'>Заявка успешно оформлена</p>
						<div>
							<p className='order-p'>В ближайшее время с Вами свяжется наш менеджер</p>
						</div>
						<div className='margin-bottom'></div>
					</div>
				</div>
			</div>) : null}
			{checkNumber ? (<DeliveryForm elem={checkNumber} setCheckNumber={setCheckNumber} />) : (<div>
				{hamburger ? <Hamburger setHamburger={setHamburger} dataValue={dataValue} /> :
					<a className='fixed-whatsapp' href={dataValue.fixedWhats}>
						<svg className='fixed-whatsapp-icon'></svg>
					</a>}
				<div className='header-background background-number' />
				<div>
					{(isScreenXl) ? (
						<div className='header'>
							<div className='header-left-logo'>
								<div className='header-menu'>
									<div className='header-content-logo-bezlimit'>
										<div className='header-icon-logo'></div>
										<p className='header-logo-bezlimit'>Безлимит Клуб</p>
									</div>
									<ScrollLink className='header-menu-h' to='baseNumbers' smooth={true}>База номеров</ScrollLink>
									<ScrollLink className='header-menu-h' to='rewiew' smooth={true}>Отзывы</ScrollLink>
									<ScrollLink className='header-menu-h' to='about' smooth={true}>О нас</ScrollLink>
									<ScrollLink className='header-menu-h' to='part' smooth={true}>Стать партнером</ScrollLink>
								</div>
								<div className='link-item-pk'>
									<div className='div_phones'>
										<a className='link-phone' href={'tel:' + dataValue.numberPhone}>{dataValue.numberPhone}</a>
										{dataValue.numberPhone2 ? <a className='link-phone' href={'tel:' + dataValue.numberPhone2}>{dataValue.numberPhone2}</a> : <></>}
									</div>									<div className='link-icon-pk'>
										<Linkwhats dataValue={dataValue} />
										<Linktelegram dataValue={dataValue} />
										<Linkvk dataValue={dataValue} />
									</div>
								</div>
							</div>
						</div>
					) : (<div className='header'>
						<div className='header-left' onClick={() => setHamburger(true)}><HiMenu className='icon-menu' /></div>
						<div className='link-item'>
							<div className='div_phones'>
								<a className='link-phone' href={'tel:' + dataValue.numberPhone}>{dataValue.numberPhone}</a>
								{dataValue.numberPhone2 ? <a className='link-phone' href={'tel:' + dataValue.numberPhone2}>{dataValue.numberPhone2}</a> : <></>}
							</div>
							<div className='link-icon'>
								<div></div>
								<Linkwhats dataValue={dataValue} />
								<Linktelegram dataValue={dataValue} />
								<Linkvk dataValue={dataValue} />
							</div>
						</div>
					</div>)}

					<div className='header-content'>
						<p className='header-content-font h5'>Добро пожаловать в </p>
						<div className='header-content-logo-bezlimit margin-bottom'>
							{(isScreenXl) ? null : (<div className='header-icon-logo'></div>)}
							<p className='header-content-bezlimit'>Безлимит Клуб</p>
						</div>
						<div name='baseNumbers'></div>
						<div>
							<p className='header-content-font h6'>{dataValue.baseNumbersP1}</p>
							<p className='header-content-font h6'>{dataValue.baseNumbersP2}</p>
						</div>
					</div>
					<div className='header-content-button'>
						<Link className='link-button' to="/number">
							<button className='button-mobile' type='button'>Каталог номеров</button>
						</Link>
					</div>
					<div className='catalog'>
						{!error ? Category.map((item) => {
							if (item.name !== 'Бриллиант') {
								return (
									<div key={item.id} className='catalog-tarif'>
										<div className='catalog-left-logo'>
											<img alt='' className='icon' src={`${process.env.PUBLIC_URL}/image/category/${item.img}`} />
											<p className='catalog-tarif-h'>{item.name}</p>
										</div>
										<GetCatalog tarifs={tarifs} dataValue={dataValue} category={item.category} setCheckNumber={setCheckNumber} setError={setError} />
									</div>
								)
							}
							return (<div key={item.id}></div>)
						}) : <></>}
					</div>
				</div>
				<div className='rewiew-wrapper'>
					<div name='rewiew'></div>
					<p className='rewiew-h'>Отзывы наших счастливых клиентов</p>
					<Swiper
						navigation={navigation}
						modules={[Pagination, Navigation]}
						className="mySwiper rewiew-card"
						slidesPerView={numberSlider}
						spaceBetween={spaceBetween} >
						{dataValue.comment?.map(item => {
							return (
								<SwiperSlide key={item.id}>
									<div className='rewiew-card-content'>
										<div className='rewiew-card-header'>
											<img alt='' className='rewiew-card-ava' src={item.img} />
											<div className='rewiew-card-name-star'>
												<p className='card-user'>{item.name}</p>
												<div className='rewiew-card-stars'>
													<svg className='rewiew-card-star' />
													<svg className='rewiew-card-star' />
													<svg className='rewiew-card-star' />
													<svg className='rewiew-card-star' />
													<svg className='rewiew-card-star' />
												</div>
											</div>
										</div>
										<p className='card-user-rewiew'>{item.massage}</p>
									</div>
								</SwiperSlide>)
						})}
					</Swiper>
				</div>
				<div className='tarifffree-wrapper'>
					<p className='tarifffree'>{dataValue.tariffFreeH}</p>
					<p className='tarifffree-text3'>{dataValue.tariffFreeP}</p>
					<div className='tarifffree-content-button'>
						<a className='link-button' href={dataValue.know}>
							<button className='button-mobile' type="button">Узнать</button>
						</a>
					</div>
				</div>
				<div className='about-wrapper'>
					<div name='about'></div>
					<p className='about-h'>О нас</p>
					<div className='video'>
						<video width='100%' muted loop controls src={dataValue.aboutVideo}></video>
					</div>
					<p className='about-p'>{dataValue.aboutVideoP}</p>
					<div className='about-grid-column'>
						{aboutColumns?.map((item) => {
							return (
								<div key={item.id}>
									<p className='about-big-p'>{item.big_p}</p>
									<p className='about-little-p'>{item.little_p}</p>
								</div>
							)
						})}
					</div>

					<Swiper
						navigation={navigation}
						modules={[Pagination, Navigation]}
						className="mySwiper rewiew-card"
						slidesPerView={numberSlider}
						spaceBetween={spaceBetween} >
						{imgList?.map((item) => {
							return (
								<SwiperSlide key={item.name}>
									<img alt='' className='about-image' src={item.image} />
								</SwiperSlide>
							)
						})}
					</Swiper>

				</div>
				<div className='advantage-wrapper'>
					<p className='advantage-h'>Почему выбирают нас?</p>
					<div className='advantage-cards'>
						{advantageList?.map(item => {
							const icon = Advantage.find(elem => elem.id === item.id).icon;
							return (
								<div key={item.id} className='advantage-card'>
									<img alt='' className='card-icon' src={`${process.env.PUBLIC_URL}/image/advantage/${icon}`} />
									<p className='card-h'>{item.h}</p>
									<p className='card-p'>{item.p}</p>
								</div>)
						})}
					</div>
				</div>
				<div className='back'>
					<div name='part'></div>
					<div className='back-margin'>
						<div className='back-form'>
							<div className='form-left-pk'>
								<p className='back-form-h'>{dataValue.becomePartnerH}</p>
								<p className='back-form-p'>{dataValue.becomePartnerP}</p>
							</div>
							<form className='form-pk'>
								<input className='back-form-input' autoComplete='name' type='name' placeholder='Имя Фамилия' name="name" value={name} onChange={(e) => handleName(e)} ></input>
								<div className='div-error-tel'>
									<InputMask className='back-form-input' autoComplete='tel-national' name='phone' type='phone' mask="+7(999) 999-99-99" placeholder="+7(000) 000-00-00" value={numberPhone} onChange={(e) => handlePhoneChange(e)} onBlur={() => handleBlur()} />
									{errorTel ? <p className='back-form-p error-tel'>Введите номер телефона</p> : <></>}
								</div>
								<button className='button-back' disabled={!name || !numberPhone || !checkbox || numberPhone.indexOf('_') !== -1} type="button" onClick={() => submitForm()}>Отправить</button>
								<div className='back-checkbox-div'>
									<input className='back-checkbox' type="checkbox" id='checkbox1' value={checkbox} onChange={() => setCheckbox(!checkbox)} />
									<label className='back-checkbox-p' htmlFor='checkbox1'>Согласен с правилами обработки<div className='back-checkbox-p-yellow'>Персональных данных</div></label>
								</div>
							</form>
						</div>
					</div>
					<div className='footer'>
						{(isScreenLg) ? (<div className='footer-row-pk'>
							<div>
								<div className='footer-content-logo-bezlimit'>
									<div className='footer-icon-logo'></div>
									<p className='footer-content-bezlimit'>Безлимит Клуб</p>
								</div>
								<p className='footer-content-bezlimit-p'>{dataValue.footerPDesktop}</p>
								<p className='footer-content-bezlimit-p'>{dataValue.additionalField}</p>
							</div>
							<div className='footer-grid-link'>
								<div className='footer-menu-div'>
									<p className='footer-menu-div-h'>Меню</p>
									<ScrollLink className='footer-menu-div-p' to='baseNumbers' smooth={true}>База номеров</ScrollLink>
									<ScrollLink className='footer-menu-div-p' to='rewiew' smooth={true}>Отзывы</ScrollLink>
									<ScrollLink className='footer-menu-div-p' to='about' smooth={true}>O нас</ScrollLink>
								</div>
								<div className='footer-link-div'>
									<p className='footer-link-div-h'>Контакты</p>
									<div className='div_phones'>
										<a className='footer-link-phone' href={'tel:' + dataValue.numberPhone} >{dataValue.numberPhone}</a>
										{dataValue.numberPhone2 ? <a className='footer-link-phone' href={'tel:' + dataValue.numberPhone2} >{dataValue.numberPhone2}</a> : <></>}
									</div>
									<div className='link-icon'>
										<Linkwhats dataValue={dataValue} />
										<Linktelegram dataValue={dataValue} />
										<Linkvk dataValue={dataValue} />
										<div></div>
									</div>
								</div>
								<div className='footer-work-mode-div'>
									<p className='footer-work-mode-div-h'>Режим работы</p>
									<p className='footer-work-mode-div-p'>Без выходных</p>
									<p className='footer-work-mode-div-p'>Круглосуточно</p>
								</div>
							</div>
						</div>) : (<div><div>
							<div className='footer-content-logo-bezlimit'>
								<div className='footer-icon-logo'></div>
								<p className='footer-content-bezlimit'>Безлимит Клуб</p>
							</div>
							<p className='footer-content-bezlimit-p'>{dataValue.footerPMobile}</p>
							<p className='footer-content-bezlimit-p'>{dataValue.additionalField}</p>
						</div>
							<div className='footer-div'>
								<div className='footer-menu-div'>
									<p className='footer-menu-div-h'>Меню</p>
									<ScrollLink className='footer-menu-div-p' to='baseNumbers' smooth={true}>База номеров</ScrollLink>
									<ScrollLink className='footer-menu-div-p' to='rewiew' smooth={true}>Отзывы</ScrollLink>
									<ScrollLink className='footer-menu-div-p' to='about' smooth={true}>O нас</ScrollLink>
								</div>
								<div className='footer-link-div'>
									<p className='footer-link-div-h'>Контакты</p>
									<div className='div_phones'>
										<a className='footer-link-phone' href={'tel:' + dataValue.numberPhone} >{dataValue.numberPhone}</a>
										{dataValue.numberPhone2 ? <a className='footer-link-phone' href={'tel:' + dataValue.numberPhone2} >{dataValue.numberPhone2}</a> : <></>}
									</div>									<div className='link-icon'>
										<Linkwhats dataValue={dataValue} />
										<Linktelegram dataValue={dataValue} />
										<Linkvk dataValue={dataValue} />
										<div></div>
									</div>
								</div>
							</div>
							<div className='footer-work-mode-div'>
								<p className='footer-work-mode-div-h'>Режим работы</p>
								<p className='footer-work-mode-div-p'>Без выходных</p>
								<p className='footer-work-mode-div-p'>Круглосуточно</p>
							</div></div>)}

						<div className='footer-bottom'></div>
						<p className='footer-bottom-p'>2012-2023 © ООО «Альгена»</p>
					</div>
				</div>
			</div>
			)}
		</div>
	);
}

function GetCatalog({ category, setCheckNumber, setError, tarifs, dataValue }) {
	const [numbersPhone, setNumbersPhone] = useState([]); // массив полученных номеров

	const [loading, setLoading] = useState(false);
	const [indexMap, setIndex] = useState(3); // показывать по 3
	const [addRequest, setAddRequest] = useState(false) // дополнительный запрос
	const [message, setMessage] = useState('');

	useEffect(() => {
		setLoading(true);
		const axiosData = async () => {
			const response = await axios(`https://www.anncom.ru/dialer/lendingbezlimit/main.py`, {
				params: {
					user_id: localStorage.getItem('bez_session'),
					pattern: 'categories',
					phone_categories: category,
					pageLimit: 13,
				}
			}).finally(() => setLoading(false))
			setMessage(response.data.response.message)
			setNumbersPhone(n => [...n, ...response.data.response.items])
			if (response.data.response.message === 'not_found') {
				setError(true);
			}
		}
		axiosData();

	}, [category, addRequest, setError])

	function add() {
		setAddRequest(!addRequest);
		setIndex(indexMap + 10)
	}
	console.log(
	 numbersPhone)

	return (
		<>
			{numbersPhone.map((item, index) => index < indexMap ? <CardCatalog tarifs={tarifs} dataValue={dataValue} key={index} elem={item} setCheckNumber={setCheckNumber} /> : null)}
			{loading ? <div className="wrap_number"><ReactLoading type='spinningBubbles' color='#fff' height={'50px'} width={'50px'} />
			</div> : (indexMap > 2 ? (message === 'stop' ? null : <button className='button-catalog' onClick={() => add()}>Загрузить еще</button>)
				: <></>)}
		</>
	)
}

function Hamburger({ setHamburger, dataValue }) {
	return (
		<div>
			<div className='hamburger-back' onClick={() => setHamburger(false)}></div>
			<div className='hamburger'>
				<div className='hamburger-button' onClick={() => setHamburger(false)}>Закрыть</div>
				<div className='hamburger-menu'>
					<ScrollLink className='hamburger-menu-h' to='baseNumbers' smooth={true} onClick={() => setHamburger(false)}>База номеров</ScrollLink>
					<ScrollLink className='hamburger-menu-h' to='rewiew' smooth={true} onClick={() => setHamburger(false)}>Отзывы</ScrollLink>
					<ScrollLink className='hamburger-menu-h' to='about' smooth={true} onClick={() => setHamburger(false)}>О нас</ScrollLink>
					<ScrollLink className='hamburger-menu-h' to='part' smooth={true} onClick={() => setHamburger(false)}>Стать партнером</ScrollLink>
				</div>
				<div className='hamburger-link'>
					<p className='hamburger-link-h'>Контакты</p>
					<div className='div_phones'>
						<a className='hamburger-link-p' href={'tel:' + dataValue.numberPhone}>{dataValue.numberPhone}</a>
						{dataValue.numberPhone2 ? <a className='hamburger-link-p' href={'tel:' + dataValue.numberPhone2}>{dataValue.numberPhone2}</a> : <></>}
					</div>
					<p className='hamburger-link-h'>Напишите нам в соц. сетях</p>
					<div className='hamburger-link-icon'>
						<a className='hamburger-icon icon-whatsapp' href={dataValue.WhatsApp}> </a>
						<a className='hamburger-icon icon-telegram' href={dataValue.Telegram}> </a>
					</div>
					<p className='hamburger-link-h'>Мы в VK</p>
					<div className='hamburger-link-icon'>
						<a className='hamburger-icon icon-vk' href={dataValue.VK}> </a>
					</div>
				</div>
			</div>
		</div>)
}

export function CardCatalog({ elem, setCheckNumber, tarifs, dataValue }) {
	const [checkTarif, setCheckTarif] = useState(false);
	const [notification, setNotification] = useState(false);

	// const tarifs1 = [
	// 	{ id: 1, price: 850, minutes: 400, sms: 300, gb: 20 }, { id: 2, price: 790, minutes: 700, sms: 300, gb: 30 },
	// 	{ id: 3, price: 1000, minutes: 700, sms: 300, gb: 30 }, { id: 4, price: 1250, minutes: 900, sms: 300, gb: 30 },
	// 	{ id: 5, price: 1500, minutes: 900, sms: 300, gb: 40 }, { id: 6, price: 2000, minutes: 2000, sms: 1000, gb: 50 },
	// 	{ id: 7, price: 2500, minutes: 5000, sms: 1000, gb: 60 }, { id: 8, price: 3000, minutes: "Ꝏ", sms: 1000, gb: 60 },
	// 	{ id: 9, price: 4000, minutes: "Ꝏ", sms: 1000, gb: 60 },]
	console.log(tarifs);
	

	const tarif = tarifs?.find(el => el.price === elem.tariff_price)

	function checkNumber(elem) {
		setCheckNumber(elem);
		window.scrollTo(0, 0)
	}

	return (
		<div className='catalog-card-width' >
			{notification ? (<div>
				<div className='hamburger-back' onClick={() => setNotification(false)}></div>
				<div className='notification-wrapper'>
					<HiOutlineX className='notification-icon' onClick={() => setNotification(false)} />
					<div className='notification-grid'>
						<div>
							<p style={{marginBottom:"10px"}} className='notificarion-h'>Оформление номера</p>
							
							<p className='notification-phone'>{elem.phone}</p>
							<div className='notification-header-row'>
								{/* <p className='notification-price'>{elem.tariff_price} </p>
								<p className='notification-description-h'> ₽</p> */}
							</div>
						</div>
						<div style={{display:"flex", justifyContent:"center"}}>
							<img src={dataValue["image_tarif_photo_"+tarif?.id]} style={{width:"90%", maxWidth:"350px"}} />
							{/* {DetailsNotif.map(item => {
								if (elem.tariff_price !== 590 || item.name !== 'Видео') {
									return (
										<div className='details-grid' key={item.id}>
											<div>
												{(item.img?.map(elem => {
													return (<img key={elem} alt='' className='social-icon' src={`${process.env.PUBLIC_URL}/image/social/${elem}`}></img>
													)
												}))}
											</div>
											<p className='notification-detail-p'>Безлимит на {item.name}</p>
										</div>
									)
								} else {
									return (<></>)
								}
							})} */}
						</div>
						{/* <div>
							<div className='notification-description-grid'>
								<div className='notification-description-row'>
									<p className='notification-p'>{tarif.gb}</p>
									<p className='notification-description-h'>Гб</p>
								</div>
								<div className='notification-description-row'>
									<p className='notification-p'>{tarif.minutes}</p>
									<p className='notification-description-h'>мин</p>
								</div>
								<div className='notification-description-row'>
									<p className='notification-p'>{tarif.sms}</p>
									<p className='notification-description-h'>sms</p>
								</div>
							</div>
						</div> */}
					</div>
					<div className='button-notification-wrapper'>
						<button className='button-notification' onClick={() => checkNumber(elem)}>Продолжить</button>
					</div>
					<div className='margin-bottom'></div>
				</div>
			</div>) : null}
			<div className='catalog-card'>
				<div className='catalog-card-header' onClick={() => setCheckTarif(!checkTarif)}>
					<div className='catalog-card-phone'>{elem.phone}</div>
					{checkTarif ? <HiOutlineChevronUp className='card-icon-chevron' /> : <HiOutlineChevronDown className='card-icon-chevron' />}
				</div>
				{checkTarif ? (<div >
					<div className='catalog-card-descriptions'>
						<div className='catalog-card-description'>
							<p className='catalog-card-description-h'>Тариф</p>
							<p className='catalog-card-description-p'>
								Безлимит {elem.tariff_price}
							</p>
						</div>
						{/* <div className='catalog-card-description'>
							<p className='catalog-card-description-h'>Цена</p>
							<p className='catalog-card-description-p'>
								{elem.tariff_price} ₽
							</p>
						</div> */}
						{/* <div className='catalog-card-description'>
							<p className='catalog-card-description-h'>Минуты</p>
							<p className='catalog-card-description-p'>
								{tarif.minutes} мин.
							</p>
						</div>
						<div className='catalog-card-description'>
							<p className='catalog-card-description-h'>Интернет</p>
							<p className='catalog-card-description-p'>
								{tarif.gb} ГБ
							</p>
						</div>
						<div className='catalog-card-description'>
							<p className='catalog-card-description-h'>SMS</p>
							<p className='catalog-card-description-p'>
								{tarif.sms}
							</p>
						</div> */}
					</div>
					<div className='catalog-card-button'>
						<button className='button-mobile' type="button" onClick={() => setNotification(true)}>Оформить заявку</button>
					</div>
				</div>) : null}
			</div>
		</div>
	)
}

function Linkvk({ dataValue }) {
	return (
		<a className='icon icon-vk' href={dataValue.VK}> </a>
	)
}

function Linktelegram({ dataValue }) {
	return (
		<a className='icon icon-telegram' href={dataValue.Telegram}> </a>
	)
}
function Linkwhats({ dataValue }) {
	return (
		<a className='icon icon-whatsapp' href={dataValue.WhatsApp}> </a>
	)
}

export default Home;
