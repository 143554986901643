import { React, useState, useEffect, useRef } from "react";
import './S_Form.scss';
import { useResize } from './use-resize';
import axios from "axios";
import { CardCatalog } from "./Home";
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { HiArrowLeft } from "react-icons/hi";
import { DeliveryForm } from "./DeliveryForm";
import { Category } from "./data";

const FormReg = () => {
	const { isScreenLg } = useResize();
	const [tarifs, setTarifs] = useState(null)
	const [dataValue, setDataValue] = useState('');

	useEffect(() => {
		axios({
			method: 'get',
			url: 'https://api.bezlimit.club/all_data'
		}).then(res => {
			setDataValue(res.data.Response);
			setTarifs(JSON.parse(res.data.Response.tarifs))
		})
		
	}, [])

	// const tarifs = [
	// 	{ id: 1, price: 850, minutes: 400, sms: 300, gb: 20 }, { id: 2, price: 790, minutes: 700, sms: 300, gb: 30 },
	// 	{ id: 3, price: 1000, minutes: 700, sms: 300, gb: 30 }, { id: 4, price: 1250, minutes: 900, sms: 300, gb: 30 },
	// 	{ id: 5, price: 1500, minutes: 900, sms: 300, gb: 40 }, { id: 6, price: 2000, minutes: 2000, sms: 1000, gb: 50 },
	// 	{ id: 7, price: 2500, minutes: 5000, sms: 1000, gb: 60 }, { id: 8, price: 3000, minutes: "Ꝏ", sms: 1000, gb: 60 },
	// 	{ id: 9, price: 4000, minutes: "Ꝏ", sms: 1000, gb: 60 },]

	const NumbersInputs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

	const [values, setValues] = useState(['', '', '', '', '', '', '', '', '', '']);
	const [listTarif, setListTarif] = useState({ id: 101, price: -9, minutes: 0, sms: 0, gb: 0 },);
	const [checkCategory, setCheckCategory] = useState('')
	const [phonesResult, setPhonesResult] = useState([]);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [inputFocus, setInputFocus] = useState(false)

	const [checkNumber, setCheckNumber] = useState('');

	let stylesNumber = {
		"borderRadius": "6px",
		"border": "0.3px solid #C2C2C2",
		"boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
		"margin": "0 5px",
		"padding": "2px",
		"width": "46px",
		"height": "56px",
		"fontSize": "32px",
		"boxSizing": "border-box",
		"color": "#fff",
		"backgroundColor": "transparent",
		"borderColor": "#EEE0AB",
		"textAlign": "center",
	}
	let stylesNumberPhone = {
		"borderRadius": "6px",
		"border": "0.3px solid #C2C2C2",
		"boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
		"margin": "0 3px",
		"width": "7.5vw",
		"height": "46px",
		"fontSize": "22px",
		"boxSizing": "border-box",
		"color": "#fff",
		"backgroundColor": "transparent",
		"borderColor": "#EEE0AB",
		"textAlign": "center",
		"padding": "0",
		"overflow": "visible"
	}

	const debouncedSearchTerm = useDebounce(values, 800);

	const [addRequest, setAddRequest] = useState(false) // дополнительный запрос


	useEffect(() => {
		setMessage('')
		const newValues = debouncedSearchTerm.slice()
		for (let i = 0; i < newValues.length; i++) {
			if (newValues[i] === "") {
				newValues[i] = "N"
			}
		}

		if (listTarif.id === 101) {
			listTarif.price = ''
		}

		setLoading(true)

		const axiosData = async () => {
			const response = await axios(`https://www.anncom.ru/dialer/lendingbezlimit/main.py`, {
				params: {
					user_id: localStorage.getItem('bez_session'),
					tariff: listTarif.price,
					phone_pattern: newValues.join(''),
					region: '',
					pattern: "categories",
					phone_categories: checkCategory.category,
					pageLimit: 25,
				}
			}).finally(() => (setLoading(false)))
			setMessage(response.data.response.message)
			if (response.data.response.message !== "not_found" && response.config.params.phone_pattern === newValues.join('')) {
				setPhonesResult(n => [...n, ...response.data.response.items])
			}
		}
		axiosData()
	}, [debouncedSearchTerm, listTarif, addRequest, checkCategory])

	function setClear() {
		setPhonesResult([]);
		setLoading(true);
		setValues(['', '', '', '', '', '', '', '', '', '']);
		setListTarif({ id: 101 });
		setCheckCategory('')
	}

	function apply() {
		setLoading(true);
		setPhonesResult([]);
		setAddRequest(!addRequest);
	}
	const inputRefs = useRef([]);

	function handleKeyDown(e, index) {
		if (e.key === "Backspace" && e.target.value === "" && index !== 0) {
			inputRefs.current[index - 1].focus();
		} else if (e.key === "ArrowLeft" && e.target.selectionStart === 0 && index !== 0) {
			inputRefs.current[index - 1].focus();
		} else if (e.key === "ArrowRight" && e.target.selectionStart === e.target.value.length && index !== inputRefs.current.length - 1) {
			inputRefs.current[index + 1].focus();
		} else if (e.key.length === 1 && /\d/.test(e.key) && index !== inputRefs.current.length - 1 && e.target.value !== "" && e.target.value.length <= 2 && inputFocus !== index) {
			inputRefs.current[index + 1].focus();
		}
	};

	function handleChange(e, index) {
		if (NumbersInputs.includes(e.target.value) || e.target.value === "") {
			setLoading(true);
			setPhonesResult([]);

			const newValues = values;
			values.splice(index, 1, e.target.value)
			setValues(newValues);
		}
	};

	function handleInput(e) {
		const input = e.target;
		const currentValue = input.value;
		setInputFocus(false)

		if (currentValue.length > 1) {
			input.value = currentValue.slice(1);
		} else {
			input.value = currentValue.slice(0);
		}
	};

	function handleChangeTarif(item) {
		if (item.price === listTarif.price) {
			setListTarif({ id: 101, price: -9, minutes: 0, sms: 0, gb: 0 });
		} else {
			setListTarif(item);
		}
		setPhonesResult([]);
		setLoading(true);

	}

	function handleChangecategory(item) {
		if (item === checkCategory) {
			setCheckCategory('')
		} else {
			setCheckCategory(item);
		}
		setPhonesResult([]);
		setLoading(true);
	}

	//Return jsx
	return (
		<div>
			<a className='fixed-whatsapp' href='https://wa.me/79389887777?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5'>
				<svg className='fixed-whatsapp-icon'></svg>
			</a>
			{checkNumber ? (<DeliveryForm elem={checkNumber} setCheckNumber={setCheckNumber} />) : (
				<div>
					<div className='header-background background-number'></div>
					<div className='form_gold'></div>
					<div className='Index_form'>
						<Link className='link back-button-flex' to='/' >
							{isScreenLg ? <button className="back-button"><HiArrowLeft className="card-icon-chevron" /> Вернуться</button> : <HiArrowLeft className="card-icon-chevron" />}
						</Link>
						<div className='header-content-logo-bezlimit padding-top'>
							<div className='header-icon-logo'></div>
							<div className='header-content-bezlimit'>Безлимит Клуб</div>
						</div>
						<div className='wrapper_content_form'>
							<p className='header-content-font h6'>Самая большая база красивых номеров!</p>
							<p className='header-content-font h6'>Подберите свой совершенный номер телефона</p>
							<div className="wrap_number">
								{values.map((item, index) => (
									<input
										key={index}
										name='numberPhone'
										value={item}
										onChange={(e) => handleChange(e, index)}
										style={window.innerWidth < 768 ? stylesNumberPhone : stylesNumber}
										ref={(ref) => (inputRefs.current[index] = ref)}
										onKeyDown={(e) => handleKeyDown(e, index)}
										tabIndex={index + 1}
										onInput={(e) => handleInput(e)}
										onClick={() => setInputFocus(index)}
										type="text" 
										inputmode="numeric"
									/>
								))}
							</div>
						</div>
						<div className="WrapperTarifs">
							<p className="header-content-font h9">Категория</p>
							{Category.length > 0 ?
								<>
									{Category.map((item, index) => {
										return (
											<button
												key={item.id}
												className={index === checkCategory.id - 1 ?
													"btn custom_btn_tarifs WrapperTarifs_active" :
													"btn custom_btn_tarifs"
												}
												onClick={() => handleChangecategory(item)}
											>
												<img alt='' className='icon' src={`${process.env.PUBLIC_URL}/image/category/${item.img}`} />
												{item.name}
											</button>
										)
									})}
								</> : null
							}
						</div>
						<div className="WrapperTarifs">
							<p className="header-content-font h9">Тариф</p>
							{tarifs?.length > 0 ?
								<>
									{tarifs.map((item, index) => {
										return (
											<button
												key={item.id}
												className={index === listTarif.id  ?
													"btn custom_btn_tarifs WrapperTarifs_active" :
													"btn custom_btn_tarifs"
												}
												onClick={() => handleChangeTarif(item, index)}
											>

												{item.price}
											</button>
										)
									})}
								</> : null
							}
						</div>
						<div className="div-center">
							<button className="button-back catalog-card-button" type="button" onClick={() => apply()}>Применить фильтр</button>
							<button className="button-mobile" type="button" onClick={() => setClear()}>Сбросить все фильтры</button>
						</div>
						<div className="phonesResults__wrapper" />
						<div className="catalog">
							{(message === 'not_found' ?
								<div>
									<p className="header-content-font h7">К сожалению, по заданным параметрам не удалось найти доступные номера. </p>
									<p className="header-content-font h7">Пожалуйста, попробуйте изменить критерии поиска, используя другие тарифы или введите другие числа в форму поиска. </p>
									<p className="header-content-font h7">Мы постараемся найти подходящий номер для вас!</p>
								</div> :
								<div className='catalog-card-width'>
									{phonesResult.map((item, index) => {
										return (
											<CardCatalog tarifs={tarifs} dataValue={dataValue} key={index} elem={item} setCheckNumber={setCheckNumber} />
										)
									})}
									{loading ? <div className="wrap_number">
										<ReactLoading type='spinningBubbles' color='#fff' height={'50px'} width={'50px'} />
									</div> : (message === 'stop' ? null : <button className='button-catalog' onClick={() => setAddRequest(!addRequest)} >Загрузить еще</button>)}

								</div>)}

						</div>
					</div>
				</div>)}
		</div>
	)
};

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		}, [value, delay]);
	return debouncedValue;
}

export default FormReg;