import React, { useEffect, useState } from 'react';
import './App_admin.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export function InputText({ item, title, obligatorily, keyAxios, dataEdit, setEdit, isMultiText=false }) {
  const [valueItem, setValueItem] = useState(item);
  const [error, setError] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const navigate = useNavigate();

  function buttonSubmit() {
    if (!error) {
      setValueItem(valueItem.trim());
      const axiosData = () => {

        axios({
          method: 'post',
          url: 'https://api.bezlimit.club/text_field',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
            'Accept': 'application/json',
          },
          data: {
            key: keyAxios,
            val: valueItem,
          }
        }).then((res) => {
          if (res.data.Response.message === "OK") {
            setEdit(!dataEdit)
          }
        })
          .catch(function (error) {
            // navigate("/");
          })
      }
      axiosData()
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  useEffect(() => {

    (valueItem !== item) ? setEditValue(true) : setEditValue(false);
    const editText = document.getElementById(title);
    editValue ? editText.className = 'edit' : editText.className = '';

    if (valueItem === '' && !obligatorily) {
      editText.className = 'error';
      setError(true);
    } else {
      setError(false)
    }
  }, [valueItem, editValue, item, title, obligatorily])

  return (
    <form className='grid'>
      <p >{title}</p>
      <div>
        {
        isMultiText
        ?
          <textarea id={title} rows={3} value={valueItem} placeholder='Введите текст' onChange={(e) => setValueItem(e.target.value)}></textarea>
          :
          <textarea id={title} rows={3} value={valueItem} placeholder='Введите текст' onChange={(e) => setValueItem(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
        }
        
        {error ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
        {editValue ? <div className='div_button'>
          <button type='button' onClick={() => setValueItem(item)}>Отменить изменения</button>
          <button type='button' className='button_sub' onClick={() => buttonSubmit()}>Сохранить</button>
        </div> : <></>}
      </div>
    </form>
  )
}

export function InputVideo({ item, title, dataEdit, setEdit }) {
  const [video, setVideo] = useState(item);
  const [showEditVideo, setShowEditVideo] = useState(false);
  const [file, setFile] = useState()
  const navigate = useNavigate();

  function previewFile(e) {
    const fileUrl = URL.createObjectURL(e.target.files[0])
    setFile(e.target.files[0]);
    setVideo(fileUrl);
  }

  function SubmitVideo() {
    if (video !== item) {
      const data = new FormData();
      data.append('file', file)
      data.append('key', 'aboutVideo')
      axios.post('https://api.bezlimit.club/take_file', data, {
        headers: {
          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        },
      }).then((res) => {
        if (res.data.Response.message === "OK") {
          setEdit(!dataEdit)
        }
      })
        .catch(function (error) {
          navigate("/");
        })
    }
  }

  useEffect(() => {
    video === item ? setShowEditVideo(false) : <></>
  }, [video, item])

  return (
    <form className='grid'>
      <label htmlFor='video'>{title}</label>
      <div>
        <video controls src={video}>
        </video>
        {showEditVideo ? <div>
          <div className='div_button start'>
            <input id='video' type='file' accept='video/*' className="custom-file-input" onChange={(e) => previewFile(e)}></input>
          </div>
          <div className='div_button'>
            <button type='button' onClick={() => setShowEditVideo(false)}>Отменить изменения</button>
            <button type='button' className='button_sub' onClick={() => SubmitVideo()}>Сохранить</button>
          </div>

        </div> : <div>
          <button type='button' onClick={() => setShowEditVideo(true)}>Изменить</button>
        </div>}
      </div>

    </form>
  )
}

export function InputImage({ item, title, index, dataEdit, setEdit }) {
  const [image, setImage] = useState(item.image)
  const [showEditImage, setShowEditImage] = useState(false);
  const [file, setFile] = useState()

  useEffect(() => {
    image !== item.image ? setShowEditImage(true) : setShowEditImage(false);
  }, [image])

  function canselButton() {
    setShowEditImage(false);
    setImage(item.image)
  }
  const navigate = useNavigate();

  function SubmitImage(index) {
    if (image !== item.image) {
      const data = new FormData();
      data.append('file', file)
      data.append('key', 'image' + index)
      axios.post('https://api.bezlimit.club/take_file', data, {
        headers: {
          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        },
      }).then((res) => {
        if (res.data.Response.message === "OK") {
          setEdit(!dataEdit)
        }
      })
        .catch(function (error) {
          navigate("/");
        })
    }
  }

  function deleteImage() {
      axios.delete('https://api.bezlimit.club/take_file', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        },
        data: {
          key: item.name
        }
      }).then((res) => {
        if (res.data.Response.message === "OK") {
          setEdit(!dataEdit)
        }
      })
  }

  return (
    <form className='grid'>
      <label htmlFor={index}>{title}</label>
      <div>
        <div className='center'>
          <img alt='' src={image} onClick={() => setShowEditImage(true)}></img>
        </div>
        {showEditImage ? <div>
          <div className='div_button start'>
            <input id={index} type='file' accept='image/*' className="custom-file-input" onChange={(e) => {
              setFile(e.target.files[0]);
              setImage(URL.createObjectURL(e.target.files[0]))
            }}></input>
          </div>
          <div className='div_button'>
            <button type='button' onClick={() => deleteImage()}>Удалить</button>
            <button type='button' onClick={() => canselButton()}>Отменить изменения</button>
            <button type='button' className='button_sub' onClick={() => SubmitImage(index)}>Сохранить</button>
          </div>
        </div> : <div>
        </div>}
      </div>
    </form>
  )
}